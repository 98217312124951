import React, { useState, useEffect, useRef } from 'react';
import {
  Stack,
  Text,
  Button,
  Img,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  useToast,
  IconButton,
  Box,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdFileDownload, MdSearch } from 'react-icons/md';
import Footer from '../Footer';
import Logo from '../../assets/images/logo.png';
import loader from '../../assets/images/loader.gif';
import sample from '../../assets/images/sample.png';
import NameAvatar from '../../utils/NameAvatar';
import axios from 'axios';
import JSZip from 'jszip';
import background from '../../assets/images/background-dark.svg';
import mobileLogo from '../../assets/images/mobile-logo.svg';
import lowResBackground from '../../assets/images/background-dark.png';

const Webchat = () => {
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const faqRefs = useRef([]);
  const inputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const headingText = useBreakpointValue({
    base: 'Welcome to HairAdvisor.ai',
    md: 'Ask any hair related questions',
  });

  const headingSize = useBreakpointValue({
    base: '24px',
    md: '40px',
  });

  const sampleQuestionArray = [
    {
      image: sample,
      title: 'Hair Care Routine',
      question1: 'What is the best daily hair care routine for healthy hairs?',
      question2: 'How often should I wash my hair?',
    },
    {
      question1: 'What are some effective ways to promote hair growth?',
      question2: 'Do vitamins help in hair growth?',
    },
    {
      question1: 'What are the benefits of keratin treatment for hair?',
      question2: 'Is it safe to color my hair frequently?',
    },
  ];

  const [highResBackgroundLoaded, setHighResBackgroundLoaded] = useState(false);

  useEffect(() => {
    if (faqs.length > 0) {
      const lastFaqIndex = faqs.length - 1;
      faqRefs.current[lastFaqIndex]?.scrollIntoView({ behavior: 'smooth' });
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [faqs]);

  useEffect(() => {
    const img = new Image();
    img.src = background;
    img.onload = () => setHighResBackgroundLoaded(true);
  }, []);

  const handleZipResponse = async data => {
    const zip = await JSZip.loadAsync(data);
    const files = [];
    zip.forEach((relativePath, file) => {
      files.push(
        file.async('base64').then(content => ({ name: relativePath, content }))
      );
    });
    return Promise.all(files);
  };

  const createChat = async ques => {
    const currentques = ques || query;
    setLoading(true);
    if (ques) {
      setShowChat(true);
    }
    setFaqs(prevFaqs => [
      ...prevFaqs,
      {
        query: currentques,
        answer: 'Awaiting answer...',
        images: [],
      },
    ]);
    setQuery('');

    try {
      const response = await axios.post(
        'https://api.hairadvisor.ai/query',
        { user_input: currentques, chat_history: [] },
        { responseType: 'arraybuffer' }
      );

      let content = '',
        images = [];
      if (response.headers['content-type'] === 'application/zip') {
        const files = await handleZipResponse(response.data);
        images = files;
      } else {
        const responseData = JSON.parse(
          new TextDecoder().decode(new Uint8Array(response.data))
        );
        content = responseData.content;
        images = responseData.images || [];
      }

      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: content, images: images.length ? images : [] }
            : faq
        )
      );

      const historyObj = {
        role: 'user',
        content: currentques,
      };

      if (content) {
        setChatHistory(prevHistory => [
          ...prevHistory,
          historyObj,
          {
            role: 'assistant',
            content,
          },
        ]);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: 'Error retrieving answer' }
            : faq
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const updateChat = async () => {
    setLoading(true);
    setFaqs(prevFaqs => [
      ...prevFaqs,
      { query: query, answer: 'Awaiting answer...', images: [] },
    ]);
    const currentques = query;
    setQuery('');

    try {
      const response = await axios.post(
        'https://api.hairadvisor.ai./query',
        { user_input: currentques, chat_history: chatHistory },
        { responseType: 'arraybuffer' }
      );

      let content = '',
        images = [];
      if (response.headers['content-type'] === 'application/zip') {
        const files = await handleZipResponse(response.data);
        images = files;
      } else {
        const responseData = JSON.parse(
          new TextDecoder().decode(new Uint8Array(response.data))
        );
        content = responseData.content;
        images = responseData.images || [];
      }

      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: content, images: images.length ? images : [] }
            : faq
        )
      );

      const historyObj = {
        role: 'user',
        content: currentques,
      };

      if (content) {
        setChatHistory(prevHistory => [
          ...prevHistory,
          historyObj,
          {
            role: 'assistant',
            content,
          },
        ]);
      }
    } catch (error) {
      console.error('Error updating chat:', error);
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: 'Error retrieving answer' }
            : faq
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && loading === false) {
      if (faqs.length > 0) {
        updateChat();
      } else {
        setShowChat(true);
        createChat();
      }
    }
  };

  const handleChat = () => {
    setShowChat(false);
    setChatHistory([]);
    setFaqs([]);
  };

  const downloadImage = (base64Image, filename) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${base64Image}`;
    link.download = filename;
    link.click();
  };

  const renderText = text => {
    const lines = text
      ?.split(/\n/)
      .filter(
        (line, index, array) =>
          line.length >= 5 ||
          (line.length === 0 && array[index - 1]?.length !== 0)
      );

    return lines?.map((line, index) => {
      let formattedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      if (formattedLine.startsWith('###')) {
        formattedLine = `<h3>${formattedLine.slice(3).trim()}</h3>`;
      }
      return line.length === 0 ? (
        <br key={index} />
      ) : (
        <p key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />
      );
    });
  };

  const handleNavigate = () => {
    window.open(
      'https://www.paypal.com/donate/?hosted_button_id=K87FNQAS6CM42'
    );
  };
  return (
    <Stack
      fontFamily={'Inter !important'}
      direction={{ base: 'column', md: 'row' }}
      width={'100%'}
      position="relative"
      minH="100vh"
      w="100%"
      color="white"
      overflowX="hidden"
    >
      <Stack
        position="fixed"
        top={0}
        left={0}
        w="100vw"
        h="100%"
        backgroundImage={`url(${highResBackgroundLoaded ? background : lowResBackground})`}
        backgroundPosition={{ base: "calc(50% + 2.7%) top !important", md: "0% -25%" }}
        backgroundRepeat="no-repeat"
        backgroundColor={'#020716D6'}
        backgroundSize={{ base: "auto", md: "100%" }}
        zIndex={-1}
        _after={{
          content: `""`,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      />
      <Stack spacing={16} width={'100%'} px={2} zIndex={1}>
        <Stack>
          {!showChat ? (
            <Stack
              mt={0.5}
              py={8}
              minH={'90vh'}
              justifyContent={'space-between'}
              w="100%"
              alignItems={'center'}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                px={4}
                pt={4}
              >
                {isMobile && (
                  <HStack justifyContent="space-between" width="100%">
                    <HStack>
                      <Stack spacing={0} gap={4}>
                        <Img w={20} src={mobileLogo} />
                      </Stack>
                    </HStack>

                    <IconButton
                      icon={<AiOutlineMenu />}
                      size="lg"
                      colorScheme="whiteAlpha"
                      bg="transparent"
                      color="white"
                      onClick={onOpen}
                      aria-label="Open Menu"
                    />
                  </HStack>
                )}

                {!isMobile && (
                  <HStack
                    alignSelf="start"
                    spacing={6}
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Heading
                      color={'#FFFFFF'}
                      fontWeight={'200'}
                      fontSize={{ base: '20px', lg: '28px' }}
                      fontFamily={'Inter, sans-serif'}
                      letterSpacing={'0.36em'}
                    >
                      HAIRADVISOR.AI
                    </Heading>

                    <Button
                      size="sm"
                      colorScheme="whiteAlpha"
                      bg="rgba(247, 249, 251, 1)"
                      color="#1C1C1C"
                      fontSize="16px"
                      onClick={handleNavigate}
                      borderRadius="50px"
                      fontWeight={600}
                      fontFamily={'Inter !important'}
                    >
                      Contribute
                    </Button>
                  </HStack>
                )}
              </Stack>

              <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent bg="black">
                  <DrawerHeader>
                    <Stack align="center" spacing={4}>
                      <Img src={Logo} w={12} />
                    </Stack>
                    <DrawerCloseButton
                      position="absolute"
                      right="10px"
                      top="10px"
                      color="white"
                    />
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack spacing={4} align="center">
                      {/* <Button variant="ghost" color="white">
                        About
                      </Button>
                      <Button variant="ghost" color="white">
                        Disclaimer
                      </Button>
                      <Button variant="ghost" color="white">
                        FAQs
                      </Button> */}

                      <Button
                        w="100%"
                        bg="white"
                        color="black"
                        fontSize="md"
                        fontWeight="bold"
                        borderRadius="50px"
                        onClick={handleNavigate}
                      >
                        Contribute
                      </Button>

                      <Button
                        w="100%"
                        variant="outline"
                        borderColor="white"
                        color="white"
                        fontSize="md"
                        borderRadius="50px"
                        onClick={onClose}
                      >
                        New Chat
                      </Button>
                    </Stack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>

              <Stack alignItems={'center'} w={'100%'}>
                <Img
                  w={200}
                  src={Logo}
                  display={{ base: 'none', md: 'block' }}
                />
                <Stack alignItems="center" textAlign="center" w="100%" mb={6}>
                  <Heading
                    fontSize={headingSize}
                    fontWeight="800"
                    lineHeight="48.41px"
                    color="#FFFFFF"
                    fontFamily="Inter, sans-serif"
                    mb={2}
                    mt={20}
                  >
                    {headingText}
                  </Heading>
                  <Text
                    fontSize="16px"
                    color="#FFFFFF"
                    maxW="600px"
                    lineHeight="1.5"
                    fontFamily="Inter, sans-serif"
                    fontWeight="500"
                  >
                    Get free hair advice, generate images of hairstyles, product
                    and tutorial recommendations, and much more.
                  </Text>
                </Stack>
                <Stack alignItems={'center'} w={'100%'}>
                  <Stack alignItems={'center'} w={'100%'} mt={4}>
                    <InputGroup
                      border="1px solid #DAD6D6"
                      borderRadius={'full'}
                      size="lg"
                      fontSize={'md'}
                      w={{ base: '100%', md: '90%', lg: '60%' }}
                      boxShadow={'0px 8px 40px rgba(0, 0, 0, 0.1)'}
                      bg="rgba(255, 255, 255, 0.25)"
                      // backdropFilter="blur(4px)"
                      maxW="740px"
                    >
                      <Input
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        onKeyDown={handleKeyPress}
                        placeholder="Ask HairAdvisor Here"
                        _placeholder={{
                          fontSize: 16,
                          color: '#FFFFFF',
                          fontFamily: 'Inter',
                        }}
                        borderRadius="full"
                        color="white"
                      />
                      <InputRightElement>
                        <MdSearch
                          cursor={query.length > 0 ? 'pointer' : ''}
                          onClick={() => {
                            if (query && query.trim().length !== 0) {
                              setShowChat(true);
                              createChat();
                            }
                          }}
                          size={20}
                          color="#FFFFFF"
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Stack>

                  <Stack
                    w={{ base: '100%', md: '90%', lg: '60%' }}
                    maxW="740px"
                    mt={6}
                  >
                    <Text
                      fontFamily={'Livvic'}
                      fontSize="md"
                      color="#DAD6D6"
                      fontWeight="600"
                      mb={2}
                      textAlign="left"
                    >
                      Sample Questions
                    </Text>
                    <SimpleGrid spacing={3} columns={[1, 2, 3]}>
                      {sampleQuestionArray.map((obj, index) => (
                        <Stack
                          key={index}
                          spacing={4}
                          onClick={() => createChat(obj.question1)}
                          _hover={{
                            cursor: 'pointer',
                            bgColor: 'rgba(255, 255, 255, 0.25)',
                          }}
                          p={4}
                          border="1px solid #DAD6D6"
                          textAlign="left"
                          bg="rgba(255, 255, 255, 0.5)"
                          borderRadius={8}
                        >
                          <Text fontSize="sm" color="white">
                            {obj.question1}
                          </Text>
                        </Stack>
                      ))}
                    </SimpleGrid>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <>
              <Stack minH={'80vh'} py={8} justifyContent={'space-between'}>
                <Stack
                  position="absolute"
                  top={-20}
                  left={0}
                  w="100vw"
                  h="100%"
                  bgImage={`url(${background})`}
                  bgPosition="center"
                  bgRepeat="no-repeat"
                  bgSize={{ base: 'cover', md: 'contain' }}
                  opacity={0.5}
                  zIndex={-1}
                  hidden
                  display={{ base: 'block', md: 'none' }}
                />
                <Stack
                  px={{ base: 2, lg: 12 }}
                  w="100%"
                  justifyContent={'space-between'}
                  direction={{ base: 'column', md: 'row' }}
                  gap={4}
                >
                  {isMobile ? (
                    <HStack justifyContent="space-between" width="100%">
                      <HStack>
                        <Stack spacing={0} gap={4}>
                          <Img w={10} src={Logo} />
                          <Text
                            fontSize="12px"
                            fontWeight="bold"
                            color="white"
                            mt="-2px"
                            fontFamily={'Inter, sans-serif'}
                            letterSpacing={'0.36em'}
                          >
                            HAIRADVISOR.AI
                          </Text>
                        </Stack>
                      </HStack>

                      <IconButton
                        icon={<AiOutlineMenu />}
                        size="lg"
                        colorScheme="whiteAlpha"
                        bg="transparent"
                        color="white"
                        onClick={onOpen}
                        aria-label="Open Menu"
                      />
                    </HStack>
                  ) : (
                    <Stack
                      px={{ base: 2, lg: 12 }}
                      w="100%"
                      justifyContent={'space-between'}
                      direction={{ base: 'column', md: 'row' }}
                      gap={4}
                    >
                      <HStack
                        alignSelf={{ base: 'center', md: 'start' }}
                        spacing={6}
                      >
                        <Heading
                          color={'#FFFFFF'}
                          fontWeight={'200'}
                          fontSize={{ base: '20px', lg: '28px' }}
                          fontFamily={'Inter, sans-serif'}
                          letterSpacing={'0.2em'}
                        >
                          HAIRADVISOR.AI
                        </Heading>
                      </HStack>

                      <HStack
                        alignSelf={{ base: 'center', md: 'center' }}
                        spacing={6}
                      >
                        <Img w={10} src={Logo} />
                      </HStack>

                      <HStack direction="row" justifyContent="space-between">
                        <HStack
                          onClick={handleChat}
                          cursor={'pointer'}
                          bgColor={'black'}
                          borderRadius={'50px'}
                          border="1px solid #FFFFFF"
                          px={4}
                          py={2}
                          w={'fit-content'}
                          alignSelf={'center'}
                        >
                          <Text
                            color={'white'}
                            fontSize={'16px'}
                            fontWeight={'600'}
                            fontFamily={'Inter'}
                          >
                            New Chat
                          </Text>
                        </HStack>
                        <HStack
                          onClick={handleNavigate}
                          cursor={'pointer'}
                          bgColor={'rgba(247, 249, 251, 1)'}
                          borderRadius={'50px'}
                          px={4}
                          py={2}
                          w={'fit-content'}
                          alignSelf={'center'}
                        >
                          <Text
                            color={'#160211'}
                            fontSize={'16px'}
                            fontWeight={'600'}
                          >
                            Contribute
                          </Text>
                        </HStack>
                      </HStack>
                    </Stack>
                  )}
                </Stack>

                <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                  <DrawerOverlay />
                  <DrawerContent bg="black">
                    <DrawerCloseButton color="white" />

                    <DrawerHeader>
                      <Stack align="center" spacing={4}>
                        <Img src={Logo} w={12} />
                      </Stack>
                    </DrawerHeader>

                    <DrawerBody>
                      <Stack spacing={4} align="center">
                        <Button
                          w="100%"
                          bg="white"
                          color="black"
                          fontSize="md"
                          fontWeight="bold"
                          borderRadius="50px"
                          onClick={handleNavigate}
                        >
                          Contribute
                        </Button>

                        <Button
                          w="100%"
                          variant="outline"
                          borderColor="white"
                          color="white"
                          fontSize="md"
                          borderRadius="50px"
                          onClick={() => {
                            handleChat();
                            onClose();
                          }}
                        >
                          New Chat
                        </Button>
                      </Stack>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                <Stack alignItems="center" w="100%">
                  <Stack
                    id="chat-container"
                    maxW="740px"
                    w={{ base: '100%', md: '90%', lg: '60%' }}
                    h="68vh"
                    overflowY="auto"
                    borderRadius="12px"
                    px={4}
                    spacing={4}
                    alignItems="center"
                    position="relative"
                  >
                    {faqs.map((faq, index) => (
                      <Stack
                        key={index}
                        w="100%"
                        ref={el => (faqRefs.current[index] = el)}
                      >
                        <HStack w="100%" p={2} justifyContent="flex-end">
                          <Stack
                            borderRadius="50px"
                            p={3}
                            bg="white"
                            color="black"
                            maxW="85%"
                            alignSelf="flex-end"
                            ml="auto"
                            textAlign="right"
                            boxShadow="lg"
                          >
                            <Text fontSize="15px" fontWeight="600">
                              {faq.query}
                            </Text>
                          </Stack>
                          <NameAvatar name="User" />
                        </HStack>

                        <HStack
                          w="100%"
                          p={2}
                          justifyContent={{ base: 'normal', lg: 'center' }}
                          alignItems="start"
                          alignSelf="flex-start"
                        >
                          <Stack>
                            <NameAvatar name="Hair Advisor" />
                          </Stack>

                          {faq.answer === 'Awaiting answer...' ? (
                            <div style={{ borderRadius: '50px' }}>
                              <Stack
                                borderRadius="50px"
                                p={3}
                                maxW="100%"
                                //  bg="rgba(255, 255, 255, 0.2)"
                                backdropFilter="blur(4px)"
                                color="white"
                                alignSelf="flex-start"
                                mr="auto"
                                textAlign="left"
                                boxShadow="lg"
                                position="relative"
                                marginLeft={'50%'}
                              >
                                {' '}
                                <Img
                                  alignSelf="center"
                                  w={40}
                                  src={loader}
                                  position="absolute"
                                  left="50%"
                                  top="50%"
                                  transform="translate(-50%, -50%) scale(5)"
                                  borderRadius="10px"
                                  opacity="0.8"
                                  p={2}
                                />
                              </Stack>
                            </div>
                          ) : (
                            <Stack
                              borderRadius="10px"
                              p={3}
                              maxW="100%"
                              bg="rgba(255, 255, 255, 0.2)"
                              backdropFilter="blur(4px)"
                              color="white"
                              alignSelf="flex-start"
                              mr="auto"
                              textAlign="left"
                              boxShadow="lg"
                              position="relative"
                            >
                              {' '}
                              <Text fontSize="14px" fontWeight="400">
                                {renderText(faq.answer)}
                              </Text>
                            </Stack>
                          )}
                        </HStack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <HStack
                  mt={{ base: '0px', md: '4px' }}
                  mb={{ base: '10px', md: '0px' }}
                  justifyContent={'center'}
                  w="100%"
                >
                  <InputGroup
                    border="1px solid #DAD6D6"
                    borderRadius={'full'}
                    size="lg"
                    fontSize={'md'}
                    w={{ base: '100%', md: '90%', lg: '60%' }}
                    boxShadow={'0px 8px 40px rgba(0, 0, 0, 0.1)'}
                    bg="rgba(255, 255, 255, 0.25)"
                    maxW="740px"
                    mt={4}
                  >
                    <Input
                      value={query}
                      onChange={e => setQuery(e.target.value)}
                      onKeyDown={handleKeyPress}
                      placeholder="Ask HairAdvisor Here"
                      _placeholder={{
                        fontSize: 16,
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                      }}
                      borderRadius="full"
                      color="white"
                    />
                    <InputRightElement>
                      <MdSearch
                        cursor={query.length > 0 ? 'pointer' : 'default'}
                        onClick={() => {
                          if (query && query.trim().length !== 0 && !loading) {
                            setShowChat(true);
                            updateChat();
                          }
                        }}
                        size={20}
                        color="#FFFFFF"
                      />
                    </InputRightElement>
                  </InputGroup>
                </HStack>
              </Stack>
            </>
          )}
        </Stack>
        <Footer />
      </Stack>
    </Stack>
  );
};

export default Webchat;
