import React, { useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Link,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import Logo from '../assets/images/HA-footer.png';
import TwitterLogo from '../assets/images/Social icon.svg';
import FacebookLogo from '../assets/images/facebook.svg';

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({ title: '', text: '' });

  const openModal = (title, text) => {
    setModalContent({ title, text });
    onOpen();
  };

  return (
    <Box as="footer" bg="black" color="white" pt={8} ml={'-2'} mr={'-4'}>
      <VStack
        spacing={8}
        align="stretch"
        maxW="90rem"
        mx="auto"
        px={4}
        mb={4}
        display={{ base: 'none', md: 'flex' }}
      >
        <HStack justify="space-between" align="center" w="100%">
          <HStack spacing={2}>
            <Image src={Logo} alt="HairAdvisor Logo" h="30px" />
            <Text
              fontSize="16px"
              fontFamily={'Inter'}
              fontWeight={500}
              letterSpacing={'0.36em'}
            >
              HAIRADVISOR.AI
            </Text>
          </HStack>

          <HStack spacing={8}>
            <Link
              onClick={() =>
                openModal(
                  `About`,
                  `Hairadvisor.ai is owned and operated by Stephen Marinaro, "TheSalonGuy." Hairadvisor is your all-in-one source for hair-related questions and answers for consumers and hair professionals. Follow TheSalonGuy on all social platforms under @thesalonguy. For feedback or questions, <br>   <a href='mailto:stephen@thesalonguy.com' style='color: blue;'>email stephen@thesalonguy.com</a><br> <strong>This application has a daily use limit. Once the daily limit has been reached, the application will be unavailable to users until midnight EST.</strong> 
             `
                )
              }
              _hover={{ textDecoration: 'underline', color: 'gray.400' }}
              cursor="pointer"
            >
              <Text
                fontSize={'16px'}
                fontWeight={600}
                lineHeight="24px"
                p="16px"
              >
                About
              </Text>
            </Link>
            <Link
              onClick={() =>
                openModal(
                  'Disclaimer',
                  `
              Hairadvisor.ai is the property of and owned by Stephen Marinaro, "TheSalonGuy," a legal entity of Stephen Gabriel Designs, LLC. We do not guarantee nor take responsibility for any answers generated by the Hairadvisor.ai engine. We are not responsible for any results or outcomes based on answers, images, or locations that the AI engine provides as a response. Hairadvisor.ai is the copyright of TheSalonGuy and may not be duplicated. You will be held liable for any copyright infringement or misuse of the site.
            `
                )
              }
              _hover={{ textDecoration: 'underline', color: 'gray.400' }}
              cursor="pointer"
            >
              <Text
                fontSize={'16px'}
                fontWeight={600}
                lineHeight="24px"
                p="16px"
              >
                Disclaimer
              </Text>
            </Link>
            <Link
              onClick={() =>
                openModal(
                  'FAQ',
                  `Hairadvisor is powered by Al technology and was created to answer hair-related questions. You can ask it to generate high-resolution images of hairstyles and colors, locate local salons, get hair tips, advice, and more. Be specific with your questions, and keep in mind that results may vary or may not be fully accurate.
            `
                )
              }
              _hover={{ textDecoration: 'underline', color: 'gray.400' }}
              cursor="pointer"
            >
              <Text
                fontSize={'16px'}
                fontWeight={600}
                lineHeight="24px"
                p="16px"
              >
                FAQ
              </Text>
            </Link>
          </HStack>

          <Text fontSize="14px">© 2025 HairAdvisor.ai</Text>
        </HStack>

        <HStack
          justify="space-between"
          align="center"
          w="100%"
          fontFamily={'Inter'}
        >
          <Text fontSize="12px" maxW="800px" fontWeight={400} lineHeight="24px">
            Help HairAdvisor grow by contributing to the application. To donate,
            please use the link provided at the top of the page.
          </Text>
          <HStack spacing={3}>
            <IconButton
              as="a"
              href="https://x.com/thesalonguy?s=11&t=SnsGvxLNwsF1f7Er5wDgCw"
              target="_blank"
              aria-label="Twitter"
              icon={<Image src={TwitterLogo} alt="X Logo" boxSize="20px" />}
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
            <IconButton
              as="a"
              href="http://www.youtube.com/TheSalonGuy"
              target="_blank"
              aria-label="Youtube"
              icon={<FaYoutube fontSize="20px" />}
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
            <IconButton
              as="a"
              href=" https://www.facebook.com/share/18tZrbVDeG/?mibextid=wwXIfr"
              target="_blank"
              aria-label="Facebook"
              icon={
                <Image src={FacebookLogo} alt="Facebook Logo" boxSize="20px" />
              }
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
            <IconButton
              as="a"
              href="https://www.instagram.com/thesalonguy?igsh=bzNuOXFveHI4d3Qx"
              target="_blank"
              aria-label="Instagram"
              icon={<FaInstagram fontSize="20px" />}
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
          </HStack>
        </HStack>
      </VStack>

      <VStack
        spacing={4}
        align="center"
        maxW="100rem"
        mx="auto"
        px={4}
        display={{ base: 'flex', md: 'none' }}
      >
        <Image src={Logo} alt="HairAdvisor Logo" h="40px" />

        <Text fontSize="16px" fontWeight="bold">
          HAIRADVISOR.AI
        </Text>

        <HStack spacing={8}>
          <Link
            onClick={() =>
              openModal(
                'About',
                `Hairadvisor.ai is owned and operated by Stephen Marinaro, "TheSalonGuy." Hairadvisor is your all-in-one source for hair-related questions and answers for consumers and hair professionals. Follow TheSalonGuy on all social platforms under @thesalonguy. For feedback or questions, email stephen@thesalonguy.com. This has a daily use Limit. One the daily limit has been reached, the application will be unavailable to users until midnight EST. <br>
             <a href='mailto:stephen@thesalonguy.com' style='color: blue;'>email stephen@thesalonguy.com</a>.<br>
           `
              )
            }
            _hover={{ textDecoration: 'underline', color: 'gray.400' }}
            cursor="pointer"
          >
            About
          </Link>
          <Link
            onClick={() =>
              openModal(
                'Disclaimer',
                `
            Hairadvisor.ai is the property of and owned by Stephen Marinaro, "TheSalonGuy," a legal entity of Stephen Gabriel Designs, LLC We do not guarantee nor take responsibility for any answers generated by the Hairadvisor.ai engine. We are not responsible for any results or outcomes based on answers, images, or locations that the AI engine provides as a response. Hairadvisor.ai is the copyright of TheSalonGuy and may not be duplicated. You will be held liable for any copyright infringement or misuse of the site.
            `
              )
            }
            _hover={{ textDecoration: 'underline', color: 'gray.400' }}
            cursor="pointer"
          >
            Disclaimer
          </Link>
          <Link
            onClick={() =>
              openModal(
                'FAQ',
                `
            Hairadvisor is powered by Al technology and was created to answer hair-related questions. You can ask it to generate high-resolution images of hairstyles and colors, locate local salons, get hair tips, advice, and more. Be specific with your questions, and keep in mind that results may vary or may not be fully accurate.
            `
              )
            }
            _hover={{ textDecoration: 'underline', color: 'gray.400' }}
            cursor="pointer"
          >
            FAQ
          </Link>
        </HStack>

        <VStack align="center">
          <Text fontSize="14px">© 2025 HairAdvisor.ai</Text>
          <HStack spacing={3}>
            <IconButton
              as="a"
              href="https://x.com/thesalonguy?s=11&t=SnsGvxLNwsF1f7Er5wDgCw"
              target="_blank"
              aria-label="Twitter"
              icon={<Image src={TwitterLogo} alt="X Logo" boxSize="20px" />}
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
            <IconButton
              as="a"
              href="http://www.youtube.com/TheSalonGuy"
              target="_blank"
              aria-label="Youtube"
              icon={<FaYoutube fontSize="20px" />}
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
            <IconButton
              as="a"
              href="https://www.facebook.com/share/18tZrbVDeG/?mibextid=wwXIfr"
              target="_blank"
              aria-label="Facebook"
              icon={
                <Image src={FacebookLogo} alt="Facebook Logo" boxSize="20px" />
              }
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
            <IconButton
              as="a"
              href="https://www.instagram.com/thesalonguy?igsh=bzNuOXFveHI4d3Qx"
              target="_blank"
              aria-label="Instagram"
              icon={<FaInstagram fontSize="20px" />}
              size="sm"
              variant="ghost"
              color="white"
              _hover={{ color: 'gray.400' }}
            />
          </HStack>
        </VStack>

        <Text fontSize="12px" textAlign="center" maxW="300px">
          Help HairAdvisor grow by contributing to the application. To donate,
          please use the link provided at the top of the page.
        </Text>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          color="black"
          bg="rgba(255, 255, 255, 0.8)"
          backdropFilter="blur(4px)"
          borderRadius="12px"
          padding="24px"
          maxWidth="662px"
          textAlign={'justify'}
        >
          <div
            style={{ fontSize: '32px', textAlign: 'center', fontWeight: 600 }}
            textAlign="center"
            fontSize="32px"
            fontWeight={600}
          >
            {modalContent.title}
          </div>
          <ModalCloseButton />
          <div
            style={{ fontWeight: 400, fontSize: '16px' }}
            fontSize="16px"
            color="black"
            lineHeight="1.6"
          >
            <Text dangerouslySetInnerHTML={{ __html: modalContent.text }} />
          </div>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Footer;
